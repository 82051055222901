export const projectNavItems = [
  {
    title: "Clifton",
    href: "/clifton/",
    src: "cliftonExterior4After",
  },
  {
    title: "Sunvale",
    href: "/sunvale/",
    src: "sunvaleIntro2After",
  },
  {
    title: "Moncks Spur",
    href: "/moncks-spur/",
    src: "moncksSpurExterior1After",
  },
  {
    title: "Landsdowne",
    href: "/landsdowne/",
    src: "landsdowneExterior4After",
  },
  {
    title: "Soleares",
    href: "/soleares/",
    src: "solearesExterior1After",
  },
]
