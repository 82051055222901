import { motion } from "framer-motion"
import React, { useContext } from "react"
import { context } from "../../provider"
import { defaultTransition } from "../utils/easings"

const NavButton = props => {
  const { isNavOpen, setIsNavOpen } = useContext(context)

  return (
    <div
      className="py-0 cursor-pointer"
      onClick={() => setIsNavOpen(!isNavOpen)}
    >
      <svg
        {...props}
        viewBox="0 0 36 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
      >
        <line y1="0.5" x2="36" y2="0.5" stroke="currentColor" />
        <path d="M0 7.79999L19 7.79999" stroke="currentColor" />
        <path d="M0 15H28.5" stroke="currentColor" />
      </svg>
    </div>
  )
}

export default NavButton
