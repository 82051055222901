import { motion } from "framer-motion"
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-use"

import { defaultTransition } from "../utils/easings"

import AnimationLink from "../components/AnimationLink"

import LogoSVG from "../assets/svg/logo.svg"

import { projectNavItems } from "../components/projects/projectsNavItems"

import "./Navigation.scss"
import { context } from "../../provider"

const Navigation = () => {
  const { isNavOpen, setIsNavOpen } = useContext(context)

  const location = useLocation()

  useEffect(() => {
    setIsNavOpen(false)
  }, [location.href])

  return (
    <motion.div
      className="navigation"
      animate={{ x: isNavOpen ? 0 : -480 }}
      transition={defaultTransition}
      initial={{ x: -480 }}
    >
      <div
        className="overlay"
        style={{ display: isNavOpen ? "block" : "none" }}
        onClick={() => setIsNavOpen(false)}
      ></div>
      <div className="content">
        <AnimationLink
          to="/"
          style={{ color: "#94aeb9" }}
          activeStyle={{ color: "white" }}
          onClick={() => {
            setIsNavOpen(false)
          }}
        >
          <LogoSVG />
        </AnimationLink>

        <div className="projects-list">
          {projectNavItems.map((item, index) => (
            <AnimationLink
              key={index}
              to={item.href}
              activeStyle={{ color: "white" }}
              onClick={() => {
                setIsNavOpen(false)
              }}
            >
              <h4>{item.title}</h4>
            </AnimationLink>
          ))}
        </div>

        <div className="page-list">
          <AnimationLink
            to="/other-projects/"
            activeStyle={{ color: "white" }}
            onClick={() => {
              setIsNavOpen(false)
            }}
          >
            <h5>Other projects</h5>
          </AnimationLink>

          {/* <AnimationLink
            to="/about-us/"
            activeStyle={{ color: "white" }}
            onClick={() => {
              setIsNavOpen(false)
            }}
          >
            <h5>About us</h5>
          </AnimationLink> */}

          <a href="mailto:steveparratt@gmail.com" target="_blank">
            <h5>Contact</h5>
          </a>
        </div>
      </div>
    </motion.div>
  )
}

export default Navigation
