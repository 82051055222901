import { motion } from "framer-motion"
import React, { useContext } from "react"
import { defaultTransition } from "../utils/easings"

import AnimationLink from "../components/AnimationLink"
import { context } from "../../provider"
import NavButton from "./NavButton"

import "./Navbar.scss"

const Navbar = () => {
  const { isNavOpen } = useContext(context)

  return (
    <motion.div
      className="navbar"
      animate={{ color: isNavOpen ? "#ffffff" : "#3a6375" }}
      transition={defaultTransition}
    >
      <NavButton />

      <div className="navbar__stella-icon">
        <AnimationLink to="/">Stella.</AnimationLink>
      </div>
    </motion.div>
  )
}

export default Navbar
