import React, { useEffect } from "react"
import AnimationLink from "../components/AnimationLink"
import { projectNavItems } from "../components/projects/projectsNavItems"
import LogoSVG from "../assets/svg/logo.svg"

import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container mx-auto flex">
        <AnimationLink to="/">
          <LogoSVG className="footer__stella-icon" />
        </AnimationLink>
        <div>
          {/* <div className="footer__stella-title">
            <AnimationLink to="/">Stella.</AnimationLink>
          </div> */}
          <div className="footer__page-list-container">
            {projectNavItems.map(item => (
              <AnimationLink to={item.href} key={item.title} className="block">
                {item.title}
              </AnimationLink>
            ))}
            <AnimationLink to="/other-projects/" className="block mt-4">
              Other projects
            </AnimationLink>
          </div>
        </div>
        <a
          href="mailto:steveparratt@gmail.com"
          className="footer__contact-btn"
          target="_blank"
        >
          Email Us
        </a>
      </div>
    </footer>
  )
}

export default Footer
