import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useContext } from "react"
import { context } from "../../provider"
import { defaultTransition } from "../utils/easings"
import Footer from "./Footer"

import Navbar from "./Navbar"
import Navigation from "./Navigation"

const useLockBodyScroll = shouldBeLocked => {
  const lockBody = () => {
    document.body.style.overflow = "hidden"
    document.documentElement.style.overflow = "hidden"
  }
  const unlockBody = () => {
    document.body.style.overflow = ""
    document.documentElement.style.overflow = ""
  }
  useEffect(() => {
    if (shouldBeLocked) lockBody()
    if (!shouldBeLocked) unlockBody()
    return () => unlockBody()
  }, [shouldBeLocked])
}

const Layout = ({ children }) => {
  const { isNavOpen } = useContext(context)

  //useLockBodyScroll(isNavOpen)

  return (
    <>
      <Navbar />
      <Navigation />
      <motion.main
        animate={{
          //   filter: isNavOpen ? "blur(5px)" : "blur(0px)",
          //   opacity: isNavOpen ? 0.5 : 1,
          x: isNavOpen ? 120 : 0,
        }}
        className="min-h-screen"
        transition={defaultTransition}
      >
        {children}
      </motion.main>
      <Footer />
    </>
  )
}

export default Layout
