import { Link, navigate } from "gatsby"
import React, { useContext } from "react"
import { useLocation } from "react-use"
import { context } from "../../provider"

// e.preventdrfualt
// play animation
// gsap on complete
// navigate to

const AnimationLink = props => {
  const { to } = props
  const { pathname } = useLocation()
  const { pageExitAnimation, setIsNavOpen } = useContext(context)

  const navgiateAfterExitAnimation = async e => {
    if (pageExitAnimation) await pageExitAnimation()
    navigate(to)
  }

  const handleClick = e => {
    e.preventDefault()
    setIsNavOpen(false)
    if (props.onClick) props.onClick()

    if (pathname === to) return

    navgiateAfterExitAnimation(e)
  }

  return (
    <Link {...props} onClick={e => handleClick(e)}>
      {props.children}
    </Link>
  )
}

export default AnimationLink
