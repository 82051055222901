import React, { useEffect, useState } from "react"
import { useSessionStorage, useWindowSize } from "react-use"
// import { useLogStateOnUpdate } from "./src/utils/helpers"
import checkIfTouchDevice from "is-touch-device"

export const context = React.createContext()

const Provider = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [pageExitAnimation, setPageExitAnimation] = useState(() => {})
  const [isTouchDevice, setisTouchDevice] = useState(false)
  //   const [comparisonTooltip, setComparisonTooltip] = useState({
  //     isActive: true,
  //   })
  const [comparisonTooltip, setComparisonTooltip] = useSessionStorage(
    "comparisonToolTip",
    {
      isActive: true,
    }
  )

  const { width } = useWindowSize()

  useEffect(() => {
    setisTouchDevice(checkIfTouchDevice())
  }, [width])

  const providerContext = {
    isNavOpen,
    setIsNavOpen,
    pageExitAnimation,
    setPageExitAnimation,
    isTouchDevice,
    comparisonTooltip,
    setComparisonTooltip,
  }

  return <context.Provider value={providerContext}>{children}</context.Provider>
}

export default ({ element }) => <Provider>{element}</Provider>
